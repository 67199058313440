<template>
  <div class="wrapper">
    <div class="title">
      商家简介
    </div>
    <div class="content">
      <article>
        护肤在现代已经成为一门学问，人们受皮肤问题困扰不再盲目地去商店买各种化妆品来试，而是由皮肤科医生开出处方，提供针对性的护肤建议。随着人们对健康的追求日益高涨和皮肤医学的高速发展，带有更高安全性和有效性的医学护肤品已经成为不可阻挡的一股潮流。
      </article>
      
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  data() {
    return {
    };
  },
};
</script>
<style lang="less" scoped>
html{
  font-size: 37.5rem;
}
.wrapper{
  padding: 1.2rem 1.86rem;
}
.title{
  font-size: 16px;
  line-height: 2.12rem;
  color: #333;
  font-weight: bold;
}
.content{
  padding-top: 0.8rem;
  font-size: 0.93rem;
  line-height: 1.12rem;
}
article{
  font-size: 14px;
  line-height: 20px;
  color: #333;
}
</style>
